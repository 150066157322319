import React from 'react';
import styled from 'styled-components';
import {BaseInput} from "./styled";

const StyledInput = styled.input`
  ${BaseInput};
`;

const Input = ({ className, autoComplete, placeHolder, input, meta }) => {
  const { valid, touched, error } = meta;

  const isValid = !touched || valid;
  const placeholder = isValid ? placeHolder : error;
  const title = isValid ? '' : error;

  return (
    <StyledInput
      title={title}
      className={className}
      valid={isValid}
      placeholder={placeholder}
      autoComplete={autoComplete}
      {...input}
    />
  );
};

export default Input;
