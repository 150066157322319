export default {
  transparent: 'rgba(0, 0, 0, 0)',
  transparentGreen: 'rgba(18, 197, 116, 0.2)',
  black: '#000000',
  dark: '#23283b',
  blue: '#2e61df',
  green: '#12c574',
  red: '#ef3f54',
  orange: '#ff9e46',
  gray: '#8d8d8d',
  gray47: '#2f2f2f',
  gray84: '#545454',
  gray169: '#a9a9a9',
  gray235: '#ebebeb',
  lightGray: '#cdd0e5',
  whisper: '#eeeFF7',
  white: '#ffffff',
  t_white_9c: '#ffffff9c',
};
