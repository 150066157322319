import React, { useContext } from 'react';
import styled from 'styled-components';

import { Context } from './GlobalContext';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  align-items: center;
  justify-content: center;
`;

const MessageBox = () => {
  const {
    state: { message },
  } = useContext(Context);

  return message && <Container>{message}</Container>;
};

export default MessageBox;
