import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import _isEmpty from 'lodash/isEmpty';

// Components
import ArticleContent from './content/ArticleContent.jsx';
import CommentList from './comments/CommentList';
import CommentForm from './comments/CommentForm';

// Style
import { media } from '../../utils/style/media';
import { fetchArticle } from '../../commands/article/actions';
import { formatStringDate } from '../../utils/date/dateUtils';
import { categoryOptions } from '../../data/categoryOptions';
import { getMessage } from '../../i18n/i18n';
import { Circle } from '../../components/Styled';

const ContentContainer = styled.div`
  font-family: 'Muli Regular', serif;
  margin: 0 auto;
  width: 85vw;

  ${media.customUp('400')`
    width: 350px; 
  `};

  ${media.customUp('500')`
    width: 450px; 
  `};

  ${media.phoneUp`
    width: 550px; 
  `};

  ${media.tabletPortraitUp`
    width: 800px; 
  `};

  ${media.tabletLandscapeUp`
    width: 1000px; 
  `};
`;

const Image = styled.div`
  width: 100%;
  height: 200px;
  margin: 30px auto;
  display: block;
  background: url(${props => getArticleImage(props.name, 350)}) no-repeat center;
  background-size: 100% 200px;

  ${media.customUp('500')`
    background: url(${props => getArticleImage(props.name, 450)}) no-repeat center;
  `};

  ${media.phoneUp`
    background: url(${props => getArticleImage(props.name, 550)}) no-repeat center;
  `};

  ${media.tabletPortraitUp`
    background: url(${props => getArticleImage(props.name, 800)}) no-repeat center;
  `};

  ${media.tabletLandscapeUp`
    background: url(${props => getArticleImage(props.name, 1000)}) no-repeat center;
  `};
`;

const ImageSource = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  color: ${prop('theme.colors.white')};
  font-size: 0.7rem;
`;

const Title = styled.h2`
  font-family: 'Muli Black', serif;
  font-size: 1.8rem;
`;

const CategoryAndDate = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  font-size: 0.7rem;
`;

const CategoryAndDateCircle = styled(Circle)`
  background-color: ${props => props.theme.colors.black};
`;

const getArticleImage = (name, width) => `${process.env.REACT_APP_IMAGE_PATH}/${name}_article_${width}.jpg`;
const getArticleOgImage = name => `${process.env.REACT_APP_IMAGE_PATH}/${name}_og.jpg`;

class Article extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      article: {},
    };
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    const article = await fetchArticle(id);
    this.setState({ article });
  }

  render() {

    if (_isEmpty(this.state.article)) {
      return null;
    }

    const {
      article: { content, image, title, id, comments, date, category, imageSource },
    } = this.state;
    const { match } = this.props;
    const { hostname } = window.location;

    const textKey = category && categoryOptions[category].textKey;
    const ogDescription = title;
    const fullHostName = `https://${hostname}`;

    console.log({image});
    console.log({title});

    return (
      <div>
        <Helmet>
          <title>{`${title} - Lokalna Alternativa Vrbas`}</title>
          <meta name="description" content={ogDescription} />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={`${fullHostName}${match.url}`} />
          <meta property="og:description" content={ogDescription} />
          <meta property="og:type" content="article" />
          <meta property="og:image" itemProp="image" content={`${fullHostName}${getArticleOgImage(image)}`} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={ogDescription} />
          <meta property="twitter:image" content={`${fullHostName}${getArticleOgImage(image)}`} />
        </Helmet>
        <ContentContainer>
          <Image name={image}>{imageSource && <ImageSource>{`Izvor: ${imageSource}`}</ImageSource>}</Image>
          <CategoryAndDate>
            <div>{textKey && getMessage(textKey)}</div>
            <CategoryAndDateCircle />
            <div>{date && formatStringDate(date)}</div>
          </CategoryAndDate>
          <div>
            <Title>{title}</Title>
            <ArticleContent content={content} />
            <CommentForm articleId={id} />
            <CommentList comments={comments} />
          </div>
        </ContentContainer>
      </div>
    );
  }
}

export default Article;
