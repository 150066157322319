import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import MessageBox from '../MessageBox';
import NavBarNormal from './normal/NavBarNormal';
import NavBarHamburger from './hamburger/NavBarHamburger';

// Data
import navBarData from '../../data/NavBarData';

const Container = styled.div`
  width: 100%;
  position: fixed;
  font-family: 'Roboto Medium', serif;
  z-index: 1;
`;

const NavBar = () => {
  const [normalNavBarVisible, setNormalNavBarVisible] = useState(false);

  const handleNavBar = () => {
    if (window.innerWidth < 600 && normalNavBarVisible) {
      setNormalNavBarVisible(false);
    }

    if (window.innerWidth >= 600 && !normalNavBarVisible) {
      setNormalNavBarVisible(true);
    }
  };

  useEffect(
    () => {
      handleNavBar();

      window.addEventListener('resize', handleNavBar);

      return () => window.removeEventListener('resize', handleNavBar);
    },
    [normalNavBarVisible],
  );

  const NavBarComponent = normalNavBarVisible ? NavBarNormal : NavBarHamburger;

  return (
    <Container>
      <NavBarComponent navBarData={navBarData} />
      <MessageBox />
    </Container>
  );
};

export default NavBar;
