import { css } from 'styled-components';
import _map from 'lodash/map';

import RobotoRegular from '../../assets/fonts/roboto/Roboto-Regular.ttf';
import RobotoMedium from '../../assets/fonts/roboto/Roboto-Medium.ttf';
import RobotoBlack from '../../assets/fonts/roboto/Roboto-Black.ttf';
import RobotoBold from '../../assets/fonts/roboto/Roboto-Bold.ttf';
import MuliRegular from '../../assets/fonts/muli/Muli.ttf';
import MuliBlack from '../../assets/fonts/muli/Muli-Black.ttf';
import MuliBold from '../../assets/fonts/muli/Muli-Bold.ttf';

const fonts = [
  {
    name: 'Roboto Regular',
    src: RobotoRegular,
  },
  {
    name: 'Roboto Medium',
    src: RobotoMedium,
  },
  {
    name: 'Roboto Black',
    src: RobotoBlack,
  },
  {
    name: 'Roboto Bold',
    src: RobotoBold,
  },
  {
    name: 'Muli Regular',
    src: MuliRegular,
  },
  {
    name: 'Muli Black',
    src: MuliBlack,
  },
  {
    name: 'Muli Bold',
    src: MuliBold,
  }
];

const Fonts = css`
  ${_map(fonts, font => `
   @font-face {
      font-family: '${font.name}';
      src: local('${font.name}'), url(${font.src});
    }
  `).join('')}
`;


export default Fonts;
