// @flow

import React from 'react';
import styled from 'styled-components';

// Components
import Comment from './Comment';

// Types
import type { CommentType } from './Comment';

const CommentsNumber = styled.label`
  display: block;
  padding: 20px 0;
  font-weight: bold;
  font-size: 22pt;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  font-family: 'Muli Black', serif;
`;

type Props = {
  comments: Array<CommentType>,
};

const CommentList = ({ comments }: Props) => {
  const numberOfCommnets = comments ? comments.length : 0;

  return (
    <>
      <CommentsNumber>{`Komentari (${numberOfCommnets})`}</CommentsNumber>
      {comments && comments.map((comment, index) => <Comment key={index} comment={comment} />)}
    </>
  );
};

export default CommentList;
