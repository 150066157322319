import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import _map from 'lodash/map';

// Components
import Hamburger from '../../icons/Hamburger';
import NavBarHamburgerItem from "./NavBarHamburgerItem";

// Constants
import paths from "../../../utils/paths/paths";
import color from "../../../utils/style/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
`;

const NavBarItemList = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-top: 25px;
  background-color: ${prop('theme.colors.black')};
  align-items: center;

  a {
    margin: 10px 0;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  height: ${prop('theme.navbar.smallHeight')};
  justify-content: space-between;
  align-items: center;
  padding: ${prop('theme.page.mediumPadding')};
  box-sizing: border-box;
  background-color: ${prop('theme.colors.white')};
`;

const StyledNavLink = styled.div`
  text-transform: uppercase;
  font-family: 'Muli Bold', serif;
  text-decoration: none;
  color: ${color.black};
  outline: none;

  p {
    margin: 0;
  }
`;

const NavBarHamburger = ({ navBarData }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const onLogoClick = () => {
    history.push(paths.articleBlog);
    setOpen(false);
  };


  return (
    <Container>
      <MenuContainer>
        <StyledNavLink onClick={onLogoClick}>
          <p>Lokalna</p>
          <p>Alternativa</p>
        </StyledNavLink>
        <Hamburger open={open} onClick={() => setOpen(!open)} />
      </MenuContainer>
      {open && (
        <NavBarItemList>
          {_map(navBarData, ({ name, path }) => (
            <NavBarHamburgerItem path={path} name={name} onClick={() => setOpen(false)} />
          ))}
        </NavBarItemList>
      )}
    </Container>
  );
};

export default NavBarHamburger;
