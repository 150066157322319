import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Assets
import headerBackgroundImg from '../../assets/images/header.png';
import { ReactComponent as Puzzle } from '../../assets/images/puzzle.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

// Components
import { Button, LinkNoUnderLine } from '../Styled';

// Constans
import paths from '../../utils/paths/paths';
import { media } from '../../utils/style/media';

// Utils
import { getMessage } from '../../i18n/i18n';

const Container = styled.header`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${prop('theme.navbar.smallHeight')};
  background: url(${headerBackgroundImg}) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  padding: ${prop('theme.page.mediumPadding')};
  
  ${media.phoneUp`
    margin-top: ${prop('theme.navbar.height')};
  `};
  
  ${media.customUp(1100)`
    padding: ${prop('theme.page.padding')};
  `};
`;

const LogoContainer = styled.div`
  flex: 1;
  align-self: center;
  margin-right: 10px;

  ${media.customUp(800)`
    flex: 1;
  `};

  ${media.tabletLandscapeUp`
    margin-right: 30px;
  `};
`;

const LogoStyled = styled(Logo)`
  width: 100px;

  ${media.customUp(400)`
    width: 130px;
  `};

  ${media.customUp(800)`
    width: 135px;
    margin-right: 10px;
  `};
`;

const LogoText = styled.p`
  display: none;
  color: white;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;
  font-family: 'Roboto Medium', serif;

  ${media.customUp(800)`
    display: block;
  `};
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  margin: -20px 5px 20px 5px;
  color: ${props => props.theme.colors.white};
  align-items: center;

  ${media.customUp(800)`
    max-width: 415px;
    order: 1;
  `};

  ${media.customUp(1100)`
    margin: 35px 20px;
  `};
`;

const TextContent = styled.div`
  ${media.phoneUp`
    max-width: 415px;
  `};
`;

const PuzzleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: -20px;
  overflow: hidden;

  ${media.customUp(800)`
    order: 2;
    overflow: unset;
    margin-right: 0;
    flex: 3;
  `};
`;

const PuzzleStyled = styled(Puzzle)`
  height: 140px;
  width: 140px;
  margin-right: -10px;

  ${media.customUp(400)`
    height: 180px;
    width: 180px;
  `};

  ${media.customUp(800)`
    margin-left: auto;
    margin-right: 0;
  `};

  ${media.customUp(1100)`
    height: 250px;
    width: 250px;
  `};
`;

const RedButton = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  margin-top: 15px;
`;

const Name = styled.p`
  font-family: 'Roboto Medium', serif;
  text-transform: uppercase;
  margin: 0;
  font-size: 0.75rem;
  letter-spacing: 2px;
`;

const FirstMotto = styled.p`
  font-size: 1.9rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: 'Roboto Black', serif;
`;

const SecondMotto = styled(FirstMotto)`
  margin-bottom: 10px;
  margin-top: 0;
`;

const Descirption = styled.p`
  font-size: 0.9rem;
  margin: 0;
`;

const MainHeader = () => (
  <Container>
    <LogoContainer>
      <LogoStyled />
      <LogoText>Vrbas</LogoText>
    </LogoContainer>
    <PuzzleContainer>
      <PuzzleStyled />
    </PuzzleContainer>
    <TextContainer>
      <TextContent>
        <Name>{getMessage('lokal.header.name')}</Name>
        <FirstMotto>{getMessage('lokal.header.motto.one')}</FirstMotto>
        <SecondMotto>{getMessage('lokal.header.motto.two')}</SecondMotto>
        <Descirption>
          Lokalna alternativa je grupa gradjana okupljena oko ideje kritike vlasti i razoblicavanja njenog stetnog
          delovanja i odluka...
        </Descirption>
        <RedButton>
          <LinkNoUnderLine to={paths.aboutUs}>Saznaj više</LinkNoUnderLine>
        </RedButton>
      </TextContent>
    </TextContainer>
  </Container>
);

export default MainHeader;
