import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: justify;
  margin-bottom: 40px;
`;

const ArticleContent = ({ content }) => {
    const normalizedContent = content && content
        .replaceAll('\\\"', '"')
        .replaceAll("href", "target=\"_blank\" rel=\"noopener noreferrer\" href")
        .replaceAll('pdf:', `${process.env.REACT_APP_DOCUMENT_PATH}/`);

    return <Container dangerouslySetInnerHTML={{ __html: normalizedContent }} />;
};

export default ArticleContent;
