import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import Input from '../../components/final-form/Input';
import Select from '../../components/final-form/Select';
import EditableTextArea from '../../components/final-form/EditableTextArea';
import { toArticleContent } from '../../utils/parser/articleParser';

const Container = styled.form`
  display: flex;
`;

const StyledTextArea = styled(EditableTextArea)`
  border: 1px solid black;
  height: 1500px;
  margin-bottom: 10px;
`;

const TextAreaContainer = styled.div`
  width: 60vw;
  margin: 10px;
`;

const RightContainer = styled.div`
  width: 25vw;
`;

const categoryData = [
  {
    value: 'VRBAS',
    text: 'Vrbas',
  },
  {
    value: 'PROVINCE',
    text: 'Provincija',
  },
  {
    value: 'REPUBLIC',
    text: 'Republika',
  },
  {
    value: 'REGION',
    text: 'Region',
  },
];

const executeCmd = (cmd, value) => document.execCommand(cmd, false, value);

const AdminPanel = () => {
  return (
    <div>
      <div>Admin panel</div>
      <Form
        onSubmit={({ content, title, category, image, date, titleUrl, imageSource }) => {
          const query = `INSERT article (title, content, category, image, datetime, title_url, image_source) VALUES (
          "${title}", "${toArticleContent(content)}", "${category}", "${image}", "${date}", "${titleUrl}", "${imageSource}"
          );`;

          console.log(query);
        }}
        render={({ handleSubmit }) => (
          <Container onSubmit={handleSubmit}>
            <TextAreaContainer>
              <button onClick={() => executeCmd('bold')}>Bold</button>
              <button onClick={() => executeCmd('italic')}>Italic</button>
              <button onClick={() => executeCmd('removeFormat')}>Normal</button>
              <button
                onClick={() => {
                  const val = prompt('Document name');
                  executeCmd('createLink', val);
                }}
              >
                Link
              </button>
              <Field name="content" placeHolder="Content" component={StyledTextArea} />
            </TextAreaContainer>
            <RightContainer>
              <div>
                <label htmlFor="title">Title:</label>
                <Field id="title" name="title" placeHolder="Enter title" autoComplete="off" component={Input} />
              </div>
              <div>
                <label htmlFor="category">Category: </label>
                <Field
                  id="category"
                  name="category"
                  placeholder="Enter category"
                  autoComplete="off"
                  component={Select}
                  selectData={categoryData}
                />
              </div>
              <div>
                <label htmlFor="date">Date:</label>
                <Field id="date" name="date" placeHolder="Enter date" autoComplete="off" component={Input} />
              </div>
              <div>
                <label htmlFor="titleUrl">Title url:</label>
                <Field id="titleUrl" name="titleUrl" placeHolder="Enter link" autoComplete="off" component={Input} />
              </div>
              <div>
                <label htmlFor="image">Image:</label>
                <Field id="image" name="image" placeHolder="Enter image" autoComplete="off" component={Input} />
              </div>
              <div>
                <label htmlFor="imageSource">Image source:</label>
                <Field
                  id="imageSource"
                  name="imageSource"
                  placeHolder="Enter image source"
                  autoComplete="off"
                  component={Input}
                />
              </div>
              <input type="submit" value="Generate" />
            </RightContainer>
          </Container>
        )}
      />
    </div>
  );
};

export default AdminPanel;
