import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

// Components
import ArticleBlogItem from './ArticleBlogItem';

// Styles
import { media } from '../../utils/style/media';
import {fetchBlogArticles} from "../../commands/article/actions";

const Title = styled.p`
  margin: 20px 0 10px 0;
  font-size: 2em;
  font-family: 'Roboto Bold', serif;
`;

const Container = styled.section`
  display: grid;
  grid-template-columns: 90vw;
  grid-template-rows: auto;
  grid-auto-rows: 90vw;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
  justify-items: center;
  margin-bottom: 20px;

  ${media.phoneUp`
    grid-template-columns: 45vw 45vw;
    grid-auto-rows: 45vw; 
    grid-column-gap: 20px
    
    ${Title} {
      grid-column: 1 / 3;
      width: 100%;
    }
  `} 

  ${media.customUp(800)`
    grid-template-columns: repeat(2, 350px);
    grid-auto-columns: 350px;
    grid-auto-rows: 350px;
  `}
  
  ${media.tabletLandscapeUp`
    grid-template-columns: repeat(3, 350px);
    
    ${Title} {
      grid-column: 1 / 4;
    }
  `}
`;

class ArticleBlog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
    };
  }

  async componentDidMount() {
    const articles = await fetchBlogArticles();
    this.setState({ articles });
  }

  render() {
    const { articles } = this.state;

    return <Container>
        <Helmet>
          <title>Lokalna Alternativa Vrbas</title>
        </Helmet>
        <Title>Poslednje vesti</Title>
        {articles.map(article => <ArticleBlogItem key={article.id} article={article} />)}
      </Container>;
  }
}

export default ArticleBlog;
