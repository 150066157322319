// flow

const textKeys = {
  'lokal.article.category.vrbas': 'Opština Vrbas',
  'lokal.article.category.province': 'Pokrajina',
  'lokal.article.category.republic': 'Republika',
  'lokal.article.category.region': 'Region',
  'lokal.header.name': 'Lokalna alternativa Vrbas',
  'lokal.header.motto.one': 'Vreme je za buđenje!',
  'lokal.header.motto.two': 'Zajedno do promena!',
};

export const getMessage = (key: String): String => {
  const message = textKeys[key];

  if (!message) {
    throw new Error(`Not valid text key: ${key}`);
  }

  return message;
};
