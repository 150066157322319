import colors from './colors';

export const theme = {
  colors,
  page: {
    padding: '0 100px',
    mediumPadding: '0 20px;',
  },
  navbar: {
    smallHeight: '85px',
    height: '65px',
  },
  article: {
    commentSubmit: {
      width: 200,
      height: 50,
    },
  },
  screenSize: {
    small: '600px',
    medium: '900px',
    large: '1200px',
  },
};
