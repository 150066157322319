// @flow

import React from 'react';
import { withRouter } from "react-router-dom";

// Utils
import paths from '../../utils/paths/paths';

// Components
import MainHeader from "./MainHeader";
import SimpleHeader from "./SimpleHeader";

const Header = ({ location }) => {
  const isMainPage = location.pathname.includes(paths.articleBlog);

  return isMainPage ? <MainHeader /> : <SimpleHeader />;
};

export default withRouter(Header);
