import React from 'react';
import styled from 'styled-components';
import { BaseInput } from './styled';

const StyledTextArea = styled.textarea`
  ${BaseInput};
`;

const TextArea = ({ className, autoComplete, placeHolder, input, meta }) => {
  const { valid, touched, error } = meta;

  const isValid = !touched || valid;
  const placeholder = isValid ? placeHolder : error;
  const title = isValid ? '' : error;

  return (
    <StyledTextArea
      title={title}
      className={className}
      valid={isValid}
      placeholder={placeholder}
      autoComplete={autoComplete}
      {...input}
    />
  );
};

export default TextArea;
