// @flow

import React from 'react';
import styled from 'styled-components';
import {prop} from "styled-tools";

// Assets
import headerBackgroundImg from '../../assets/images/header.png';
import { ReactComponent as Puzzle } from '../../assets/images/puzzle.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { media } from "../../utils/style/media";

const Container = styled.div`
  display: flex;
  height: 15vh;
  margin-top: ${prop('theme.navbar.smallHeight')}
  padding: ${prop('theme.page.mediumPadding')};
  background: url(${headerBackgroundImg}) no-repeat center;
  background-size: cover;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  
  ${media.phoneUp`
    margin-top: ${prop('theme.navbar.height')}
  `};
  
  ${media.customUp(1100)`
    padding: ${prop('theme.page.padding')};
  `};
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
`;

const TextContainer = styled.div`
  display: none;
  margin-left: 20px;
  text-transform: uppercase;
  
  ${media.customUp(430)`
    display: block;
  `}
`;
const Name = styled.div`
  font-weight: bold;
  font-size: 1em;
  
  ${media.customUp(600)`
    font-size: 1.5em;
  `}
`;

const Place = styled.div`
  margin-top: 5px;
`;

const StyledLogo = styled(Logo)`
  width: 100px;
  
  ${media.phoneUp`
    width: 120px;
  `}
`;

const StyledPuzzle = styled(Puzzle)`
  width: 130px;
  
  ${media.phoneUp`
    width: 220px;
  `}
`;

const SimpleHeader = () => (
  <Container>
    <LogoContainer>
      <StyledLogo />
      <TextContainer>
        <Name>Lokalna</Name>
        <Name>alternativa</Name>
        <Place>Vrbas</Place>
      </TextContainer>
    </LogoContainer>
    <StyledPuzzle />
  </Container>
);

export default SimpleHeader;
