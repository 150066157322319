import React from 'react';
import _map from 'lodash/map';

const Select = ({className, input, selectData}) => {
  return (
      <select className={className} {...input}>
        {_map(selectData, ({value, text}) => (
            <option key={value} value={value}>{text}</option>
        ))}
      </select>
  );
};

export default Select;
