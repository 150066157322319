// flow

import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Styled
import { InfoIconBase } from './spinnerOverlayStyled';

const SuccessIcon = styled(InfoIconBase)`
  background-color: ${prop('theme.colors.green')};
`;

const CheckMark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  width: 8px;
  height: 16px;
  border-bottom: 3px solid ${prop('theme.colors.white')};
  border-right: 3px solid ${prop('theme.colors.white')};
  margin-bottom: 3px;
`;

const SpinnerSuccessIcon = () => (
  <SuccessIcon>
    <CheckMark />
  </SuccessIcon>
);

export default SpinnerSuccessIcon;
