// flow

import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Styled
import {InfoIconBase } from "./spinnerOverlayStyled";

const ErrorIcon = styled(InfoIconBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${prop('theme.colors.red')};
`;

const XIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  
  :before, :after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 24px;
    width: 3px;
    background-color: ${prop('theme.colors.white')};
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`;

const SpinnerErrorIcon = () => (
  <ErrorIcon>
    <XIcon />
  </ErrorIcon>
);

export default SpinnerErrorIcon;
