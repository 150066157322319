import colors from "../utils/style/colors";

export const categoryOptions = {
  VRBAS: {
    textKey: 'lokal.article.category.vrbas',
    color: colors.red,
  },
  PROVINCE: {
    textKey: 'lokal.article.category.province',
    color: colors.green,
  },
  REGION: {
    textKey: 'lokal.article.category.region',
    color: colors.orange,
  },
  REPUBLIC: {
    textKey: 'lokal.article.category.republic',
    color: colors.blue,
  },
};
