import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Components
import ArticleBlogInfo from './ArticleBlogInfo.jsx';

// Constants
import { categoryOptions } from '../../data/categoryOptions';

// Utils
import { getMessage } from '../../i18n/i18n';

// Styles
import { media } from '../../utils/style/media';

const ArticleWrapper = styled.div`
  width: 100%;
  background: url(${props => getArticleBlogImage(props.image)}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;

  ${media.phoneUp`
    :first-of-type {
      grid-column: 1 / 3;
      background: url(${props => getArticleBlogMainImage(props.image)}) no-repeat center;
      background-size: cover;
    } 
  `};
`;

const getArticleBlogImage = name => `${process.env.REACT_APP_IMAGE_PATH}/${name}_blog.jpg`;
const getArticleBlogMainImage = name => `${process.env.REACT_APP_IMAGE_PATH}/${name}_blog_main.jpg`;

const ArticleCategory = styled.div`
  margin-right: auto;
  color: white;
  padding: 8px;
  background-color: ${props => props.color};
  text-transform: uppercase;
  font-size: 75%;
  font-family: 'Roboto Medium', serif;
`;

const ArticleBlogItem = ({ article: { category, id, title, date, image, titleUrl } }) => {
  const { textKey, color } = categoryOptions[category];

  const history = useHistory();
  const url = `/vesti/${id}/${titleUrl}`;

  return (
    <ArticleWrapper image={image} onClick={() => history.push(url)}>
      <ArticleCategory color={color}>{getMessage(textKey)}</ArticleCategory>
      <ArticleBlogInfo title={title} date={date} />
    </ArticleWrapper>
  );
};

export default ArticleBlogItem;
