import React from 'react';

const EditableTextArea = ({ className, input, placeholder }) => {
  return (
    <div
      contentEditable
      className={className}
      {...input}
      placeholder={placeholder}
      onInput={event => input.onChange(event.target.innerHTML)}
    />
  );
};

export default EditableTextArea;
