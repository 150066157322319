import _has from 'lodash/has';

const domParser = new DOMParser();

const TEXT = '#text';

const createContentElement = type => text => ({
  type,
  text,
});

const createDocumentContent = (text, name) => ({
  type: 'document',
  text,
  name,
});

const ELEMENT_HANDLERS = {
  [TEXT]: createContentElement("normal"),
  DIV: createContentElement('normal'),
  B: createContentElement('bold'),
  I: createContentElement('italic'),
  A: createDocumentContent,
};

export const toArticleContent = content => {
  const document = domParser.parseFromString(content, 'text/html');

  let result = [];

  for (let child of document.body.childNodes) {
    if (!isNewLineElement(child)) {
      result.push(createParagraph(child));
    }
  }

  return JSON.stringify(result).replace(/\"/g, '\\"');
};

const createContent = element => {
  const isText = element.nodeName === TEXT;
  const handler = ELEMENT_HANDLERS[`${element.nodeName}`];

  if (isText || (element.childElementCount === 0 && handler)) {
    return [handler(element.textContent, element.href)];
  }

  let content = [];

  for (let e of element.children) {
    content.push(createContent(e)[0]);
  }

  return content;
};

const isNewLineElement = element =>
  element.tagName === 'DIV' && element.childElementCount === 1 && element.firstElementChild.tagName === 'BR';

const createParagraph = element => ({
  type: 'paragraph',
  content: createContent(element),
});
