import paths from '../utils/paths/paths';

const initialNavBarData = [
  {
    name: 'Početna',
    path: paths.articleBlog,
  },
  {
    name: 'O nama',
    path: paths.aboutUs,
  },
  {
    name: 'Kontakt',
    path: paths.contact,
  },
];

const createNavBarData = () => {
  if (process.env.NODE_ENV === "development") {
    const adminData = {
      name: 'Admin',
      path: paths.admin
    };

    initialNavBarData.push(adminData);
  }

  return initialNavBarData;
};

export default createNavBarData();
