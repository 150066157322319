import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(205, 208, 229);
`;

export const Submit = styled.input`
  font-weight: bold;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
`;

export const LinkNoUnderLine = styled(Link)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
`;

export const Button = styled.button`
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px;
  font-size: 0.7rem;
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-family: 'Roboto Black', serif;
`;

export const Circle = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.gray};
`;

