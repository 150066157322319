// @flow

import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Assets
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg';
import { ReactComponent as ActiveArrow } from '../../../assets/images/arrow-active.svg';

const StyledArrow = styled(Arrow)`
  width: 25px;
  margin-left: auto;
`;

const StyledActiveArrow = styled(ActiveArrow)`
  display: none;
  margin-left: auto;
  width: 50px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0;
  margin: 0 50px;
  border-top: 1px solid ${prop('theme.colors.gray47')};
  border-bottom: 1px solid ${prop('theme.colors.transparent')};
`;

const StyledNavLink = styled(NavLink)`
  width: 100%;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  padding: 0;
  margin: 0 !important;

  :first-child {
    ${Content} {
      border-top: 1px solid ${prop('theme.colors.transparent')};
    }
  }

  :last-child {
    ${Content} {
      border-bottom: 1px solid ${prop('theme.colors.gray47')};
    }
  }

  &:hover,
  &.active {
    background-color: ${prop('theme.colors.green')};

    ${Content} {
      border-top: 1px solid ${prop('theme.colors.transparent')};
      border-bottom: 1px solid ${prop('theme.colors.transparent')};
    }

    ${StyledArrow} {
      display: none;
    }

    ${StyledActiveArrow} {
      display: block;
    }
  }
  
  &:hover + a,
  &.active + a {
    ${Content} {
      border-top: 1px solid ${prop('theme.colors.transparent')};
    }
  }
`;

type Props = {
  path: string,
  name: string,
  onClick: Function,
}
const NavBarHamburgerItem = ({ path, name, onClick }: Props) => (
  <StyledNavLink key={path} exact to={path} onClick={onClick}>
    <Content>
      {name}
      <StyledArrow />
      <StyledActiveArrow />
    </Content>
  </StyledNavLink>
);

export default NavBarHamburgerItem;
