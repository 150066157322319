import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';
import _isFunction from 'lodash/isFunction';

import { media } from '../../utils/style/media';
import ContactImg from '../../assets/images/contanct.jpg';
import { prop } from 'styled-tools';
import { Submit } from '../../components/Styled';

import SpinnerOverlay, { SPINNER_ICON } from '../../components/spinner-overlay/SpinnerOverlay';
import Input from '../../components/final-form/Input';
import TextArea from '../../components/final-form/TextArea';
import {
  combineValidators,
  required,
  validEmail
} from '../../utils/validators';

const MainContent = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: 'Muli Regular', serif;

  ${media.customUp(500)`
    width: 500px; 
    margin-bottom: 75px;
  `} ${media.customUp(950)`
    display: flex;
    width: 900px;
    height: 450px;
    
    > div {
      width: 100%;
      padding-top: 0; 
    } 
  `};
`;

const Image = styled.div`
  padding-top: 100%;
  background: url(${ContactImg});
  background-size: cover;
`;

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${prop('theme.colors.whisper')};
`;

const TitleContainer = styled.div`
  text-align: center;
  margin: 30px 0;

  ${media.customUp(500)`
    margin: 40px 0;
  `};
`;

const Title = styled.p`
  font-size: 2rem;
  margin: 0;
  font-family: 'Muli Black', serif;

  ${media.customUp(500)`
    font-size: 2.6rem;
  `};
`;

const SubTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  font-family: 'Muli Bold', serif;
`;

const StyledForm = styled.form`
  height: 100%;
  padding: 35px 50px;
  box-sizing: border-box;
  font-size: 1rem;
`;

const ContactInput = styled(Input)`
  height: 35px;
  margin-bottom: 20px;
  padding: 0 10px;
`;

const ContactTextArea = styled(TextArea)`
  height: 135px;
  resize: none;
  padding: 10px;
`;

const ContactSubmit = styled(Submit)`
  width: 110px;
  height: 40px;
  font-size: 0.7rem;
  background-color: ${prop('theme.colors.green')};
  margin-right: 15px;
  font-family: 'Muli Black', serif;
`;

const SubmitContainer = styled.div`
  display: flex;
  height: 10%;
  margin-top: 7%;
  align-items: center;
`;

const ContactPage = () => {
  const [spinnerData, setSpinnerData] = useState({
    icon: SPINNER_ICON.IDLE,
    message: '',
  });

  const updateSpinnerData = (icon, message) => setSpinnerData({ icon, message });

  const hideSpinner = callback => {
    setTimeout(() => {
      updateSpinnerData(SPINNER_ICON.IDLE, '');
      if (_isFunction(callback)) {
        callback();
      }
    }, 3000);
  };

  return (
    <div>
      <Helmet title="Kontak - Lokalna Alternativa Vrbas" />
      <TitleContainer>
        <Title>Imate pitanja?</Title>
        <SubTitle>Budite slobodni da nas kontaktirate.</SubTitle>
      </TitleContainer>
      <MainContent>
        <Image />
        <Form
          onSubmit={async (values, form) => {
            updateSpinnerData(SPINNER_ICON.LOADING, '');

            const response = await fetch('/api/mail', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(values),
            });

            const { status, statusText } = response;
            const error = status === 404 || status === 500;

            if (error) {
              console.error("Can't send contact message: ", statusText);
              updateSpinnerData(SPINNER_ICON.ERROR, 'Greska, pokusajte ponovo.');
            }

            updateSpinnerData(SPINNER_ICON.SUCCESS, 'Komentar uspesno poslat.');
            hideSpinner(error ? null : form.reset);
          }}
          subscription={{ touched: true, submitting: true }}
          render={({ handleSubmit }) => {
            return (
              <FormContainer>
                <SpinnerOverlay icon={spinnerData.icon} message={spinnerData.message} />
                <StyledForm onSubmit={handleSubmit}>
                  <Field
                    name="name"
                    placeHolder="Ime i prezime"
                    validate={required('Ime i prezime je obavezno')}
                    autoComplete="off"
                    component={ContactInput}
                  />
                  <Field
                    name="mail"
                    placeHolder="E mail"
                    validate={combineValidators(
                      required('E mail je obavezan'),
                      validEmail('Mejl nije validan'),
                    )}
                    autoComplete="off"
                    component={ContactInput}
                  />
                  <Field
                    name="title"
                    placeHolder="Naslov poruke"
                    validate={required('Naslov poruke je obavezan')}
                    autoComplete="off"
                    component={ContactInput}
                  />
                  <Field
                    name="content"
                    placeHolder="Sadrzaj"
                    validate={required('Sadrzaj je obavezan')}
                    autoComplete="off"
                    component={ContactTextArea}
                  />
                  <SubmitContainer>
                    <ContactSubmit type="submit" value="posalji" />
                  </SubmitContainer>
                </StyledForm>
              </FormContainer>
            );
          }}
        />
      </MainContent>
    </div>
  );
};

export default ContactPage;
