// @flow

import React from "react";
import { Helmet } from "react-helmet";

const Documents = () =>(
  <div>
    <Helmet>
      <title>Dokumenti - Lokalna Alternativa Vrbas</title>
    </Helmet>
    <div>
      <h2>Dokumenti</h2>
      <p>U izradi...</p>
    </div>
  </div>
);

export default Documents;