// @flow

import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

// Data
import navBarData from '../../data/NavBarData';

// Components
import { LinkNoUnderLine } from '../Styled';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const StyledLink = styled(LinkNoUnderLine)`
  margin-left: 0.5vw;
`;

type Props = {
  location: Location,
};

const FooterNavBar = (props: Props) => (
  <Container>
    {navBarData.filter(({ path }) => props.location.pathname !== path).map(({ name, path }, index) => (
      <StyledLink key={index} to={path}>
        {name}
      </StyledLink>
    ))}
  </Container>
);

export default withRouter(FooterNavBar);
