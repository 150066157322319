import React from 'react';
import {Helmet} from 'react-helmet';
import styled from "styled-components";

import {media} from "../../utils/style/media";

const Container = styled.div`
  font-family: 'Muli Regular', serif;
  margin: 0 auto;
  width: 85vw;

  ${media.customUp('400')`
    width: 350px; 
  `};

  ${media.customUp('500')`
    width: 450px; 
  `};

  ${media.phoneUp`
    width: 550px; 
  `};

  ${media.tabletPortraitUp`
    width: 800px; 
  `};

  ${media.tabletLandscapeUp`
    width: 1000px; 
  `};
`;

const Title = styled.h2`
  font-family: 'Muli Black', serif;
  font-size: 1.8rem;
`;

const AboutUs = () => (
    <Container>
        <Helmet>
            <title>O nama - Lokalna Alternativa Vrbas</title>
        </Helmet>
        <Title>O nama</Title>
        <p>Mi smo grupa građana okupljena oko zajedničkih vrednosti sa idejom da udruženim snagama pokrenemo pozitivne
            promene u društvenoj zajednici kojoj pripadamo. Definisali smo zajedničke ciljeve i principe po kojima
            delujemo i osnovali udruženje građana “Lokalna alternativa” sa sedištem u Vrbasu.</p>
        <p>Bavimo se pitanjima od interesa za građane opštine Vrbas, pokrećemo javni dijalog u cilju iznalaženja
            najboljih rešenja za probleme koji pogađaju našu zajednicu i iniciramo njihovo rešavanje.</p>
        <p>Osnovni ciljevi su nam podizanje nivoa svesti o značaju građanskog sudelovanja i aktivnijeg učešća građana u
            procesu donošenja odluka od zajedničkog interesa; kontrola upravljanja javnim resursima, zaštita i
            unapređenje slobode govora i javnog informisanja…</p>
        <p>U Skupštini opštine Vrbas imamo svoje predstavnike od svog osnivanja. Kritičari smo aktuelne vlasti.
            Koristimo svaku priliku da rad lokalnog parlamenta približimo građanima i da se u njemu čuje glas običnih
            ljudi.</p>
        <p>Ukazujemo na nepravilnosti u radu organa javne vlasti, insistiramo na učešću javnosti u radu lokalne
            samouprave, državne uprave, javnih preduzeća i ustanova. Na taj način utičemo na ostvarivanje javnog
            interesa.</p>
        <p>Štitimo prava građana pred organima vlasti, komunalnim i drugim javnim preduzećima. Borimo se protiv
            korupcije; stranačke, rasne i svake druge vrste diskriminacije.</p>
        <p>Na tom putu sarađujemo sa svim pojednicima i organizacijama koje poštuju slične principe i dele iste
            vrednosti.</p>
        <p>Pozivamo Vas da jasno iskažete svoj stav i aktivno se uključite u borbu za uređeno i prosperitetno društvo
            jednakih mogućnosti za sve.</p>
    </Container>
);

export default AboutUs;
