import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import _map from 'lodash/map';

// Styles
import { media } from "../../../utils/style/media";

const Container = styled.div`
  display: flex;
  height: ${prop('theme.navbar.height')};
  align-items: center;
  background-color: ${prop('theme.colors.white')};
  
  ${media.phoneUp`
    padding: ${prop('theme.page.mediumPadding')};
  `};

  ${media.customUp(1100)`
    padding: ${prop('theme.page.padding')};
  `};
`;

const StyledNavLink = styled(NavLink)`
  text-transform: uppercase;
  text-decoration: none;
  color: ${prop('theme.colors.gray169')};
  font-size: 1.1rem;
  margin-right: 50px;
  border-bottom: 2px solid transparent;

  &:hover,
  &.active {
    color: ${prop('theme.colors.black')};
    border-bottom: 2px solid ${prop('theme.colors.red')};
  }
`;

const NormalNavBar = ({ navBarData }) => (
  <Container>
    {_map(navBarData, ({ name, path }) => (
      <StyledNavLink key={path} exact to={path}>
        {name}
      </StyledNavLink>
    ))}
  </Container>
);

export default NormalNavBar;
