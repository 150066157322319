// @flow
import React from 'react';
import styled from 'styled-components';

// Utils
import { dateSinceMessage } from '../../../utils/date/dateUtils';
import { Circle } from "../../../components/Styled";

const Container = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
`;

const NameDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const NameContainer = styled.span`
  font-family: 'Muli Black', serif;
`;

const CommentTime = styled.span`
 font-family: 'Muli Regular', serif; 
`;

export type CommentType = {
  name: string,
  content: string,
  date: string,
};

type Props = {
  comment: CommentType,
};

const Comment = ({ comment: { name, content, date } }: Props) => (
  <Container>
    <NameDateContainer>
      <NameContainer>{name}</NameContainer>
      <Circle />
      <CommentTime>{dateSinceMessage(date)}</CommentTime>
    </NameDateContainer>
    <div>{content}</div>
  </Container>
);

export default Comment;
