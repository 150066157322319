//paths
import paths from './paths';

//components
import ArticleBlog from '../../pages/article-blog/ArticleBlog.jsx';
import Article from '../../pages/article/Article';
import AboutUs from '../../pages/about/AboutUs.jsx';
import Documents from '../../pages/document/Documents.jsx';
import ContactPage from '../../pages/contact/ContactPage';
import AdminPanel from "../../pages/admin/AdminPanel";

const pathComponentItems = [
  {
    path: paths.articleBlog,
    component: ArticleBlog,
  },
  {
    path: paths.article,
    component: Article,
  },
  {
    path: paths.aboutUs,
    component: AboutUs,
  },
  {
    path: paths.documents,
    component: Documents,
  },
  {
    path: paths.contact,
    component: ContactPage,
  },
  {
    path: paths.admin,
    component: AdminPanel,
  }
];

export default pathComponentItems;
