const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const required = message => value => (value ? undefined : message);

export const validEmail = message => value => (emailRegex.test(value) ? undefined : message);

export const combineValidators = (...validators) => value => {
  let message = undefined;

  validators.some(validators => {
    message = validators(value);

    if (message !== undefined) {
      return true;
    }
  });

  return message;
};
