import http from '../../http/index';

export const fetchArticle = async (id) => {
  try {
    return await http.get(`/api/article/${id}`);
  } catch {
    console.error(`Error occurred during fetching article: ${id}`);
  }
};

export const fetchBlogArticles = async () => {
  try {
    return await http.get(`/api/articles`);
  } catch {
    console.error(`Error occurred during fetching blog articles`);
  }
};
