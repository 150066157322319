// @flow

import React, { useState } from 'react';
import type { Node } from 'react';

type Props = {
  children?: Node,
};

export const Context = React.createContext();

/**
 * Component which will handle global state.
 */
const GlobalContext = ({ children }: Props) => {
  const [message, setMessage] = useState(null);

  const showMessage = msg => {
    setMessage(msg);
    setTimeout(() => setMessage(null), 3000);
  };

  const context = {
    state: {
      message,
    },
    actions: {
      showMessage,
    },
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default GlobalContext;
